export const ROUTES_EN = {
  companies: 'companies',
  editCompanies: 'companies/edit',
  editClubSegment: 'edit',
  shareClubSegment: 'share',
  companyClubParam: 'clubId',
  ticket: 'share-ticket',

  play: {
    base: 'play',
    game: 'play/:id',
    raffle: 'play/:id/:raffle',
    lotteryNumberParam: 'number',
    manual: 'manual',
    rules: 'rules',
    share: 'share',
    sharePercents: 'share/percents',
    shareOptions: 'share/options',
    address: 'address',
    shipment: 'shipment',
    collection: 'collection',
    booth: 'booth',
    manualMode: {
      booths: 'booths',
      map: 'booth/:boothId',
      location: 'location',
      rules: 'rules',
    },
  },

  clubs: {
    base: 'play/clubs',
    clubSegment: 'clubs',
    newClub: 'play/clubs/:gameId/:raffleId/:tabId/new',
    newSegment: 'new',
    editClub: 'play/clubs/:gameId/:raffleId/:tabId/edit/:clubId',
    editSegment: 'edit',
    clubSms: 'play/clubs/:gameId/:raffleId/:tabId/share/sms',
    shareSegment: 'share',
    shareQueryParam: 'share',
    clubParam: 'club',
    helpSegment: 'help',

    list: ':gameId/:raffleId/:tabId',
    tabs: {
      all: 'all',
      fixed: 'fixed',
      solidary: 'solidary',
      rockets: 'rockets',
      companies: 'companies',
      private: 'private',
      share: 'share',
      shareSms: 'sms',
      contacts: 'google-contacts',
    },

    update: {
      image: 'image',
      book: 'book',
      description: 'description',
    },
    new: {
      image: 'image',
      help: 'help',
      tickets: 'tickets',
      booths: 'booths',
      options: 'options',
      map: 'map',
      completed: 'completado',
    },
    register: {
      base: ':code',
      help: 'help',
      register: 'register',
      login: 'login',
      terms: 'terms',
      data: 'data',
      phone: 'phone',
      sms: 'sms',
      load: 'load',
      tpv: 'load/tpv',
      remember: 'login/remember',
    },
  },

  tickets: {
    ticket: ':id',
    subscription: 'subscription',
    subscribe: 'subscribe',
    unsubscribe: 'unsubscribe',
    archive: 'archive',
    share: 'share',
    shareGroupSegment: 'groups',
    shareContactsSegment: 'contacts',
    sharePercents: 'share/percents',
    requestMiddlePrize: 'request-prize',
    shareOptions: 'share/options',
    buyMore: 'buy',
    edit: 'edit',
    info: 'info',
    repeat: 'repeat',
    proof: 'proof',
    proofData: 'data',
    download: 'download',
    returnPrize: 'return-prize',
    ship: 'ship',
    result: 'result',
    qr: 'qr',
    image: 'image',
    collection: 'collection',
    delete: 'delete',
    modalParam: 'modal',
  },

  booths: 'booths',
  boothParam: 'booth',
  retailers: 'retailers',
  check: 'check',
  checkParams: {
    type: 'type',
    voucher: 'voucher',
    code: 'code',
  },
  contactUs: 'contact',
  shipment: 'shipment',
  notifications: 'notifications',
  faq: 'faq',
  promos: 'promos',
  promoParam: 'code',
  redirect: 'redirection/:target',
  redirectSegment: 'redirection',
  legal: 'legal',
  plus18: 'under-18',
  responsibleGame: 'responsible-game',
  lotteryScams: 'lottery-scams',
  termsLimitLoad: 'terms-limit-load',
  selfExclusionInfo: 'self-exclusion-info',
  register: 'user/register',
  registerSegment: 'register',
  prizes: 'prizes',
  prizeParam: 'index',
  prizeView: ':index',
  sponsor: 'sponsor',
  language: 'language',

  money: {
    balance: 'balance',
    load: 'load',
    priceParam: 'price',
    screenParam: 'screen',
    transfer: 'transferencia',
    transferPreview: 'preview',
    depositPreview: 'preview',
    transferPreviewMobile: 'transferencia/preview',
    transferSpei: 'spei',
    paymentMethodParam: 'paymentMethod',
    selectMethod: 'add',
    addWorldPay: 'add/card',
    addTrustly: 'add/trustly',
    fillUserTrustly: 'add/trustly/user-data',
    addFiserv: 'add/fiserv',
    annualSummary: 'annual-summary',
  },

  selfExclusion: {
    path: 'selfexclusion',
    selfExclusion: 'selfexclusion',
    confirm: 'confirm',
    limits: {
      path: 'limits',
      max: {
        path: 'max',
        remove: {
          path: 'remove',
          info: 'info',
          questionnaire: 'questionnaire',
        },
      },
    },
  },

  state: {
    path: 'state',
    select: 'select',
  },

  groups: {
    base: 'group',
    balance: {
      base: 'balance',
      load: 'load',
      loadPending: 'load-pending',
      request: 'request',
      loadRequire: 'load-and-request',
      distribute: 'distribute',
      moneyPrizeBox: 'money-prize-box',
      moneyPrizeBoxHistory: 'history',
      distributeMoneyPrizeBox: 'distribute-money-prize',
      transferMoneyPrizeBox: 'transfer-money-prize',
    },
    play: 'play',
    games: 'games',
    tickets: 'tickets',
    ticket: 'ticket',
    chat: 'chat',
    join: 'join',
    edit: 'edit',
    members: {
      base: 'members',
      add: 'add',
      sms: 'sms',
      contacts: 'google-contacts',
    },
    options: 'options',
    new: {
      base: 'new',
      image: 'image',
      members: 'members',
      balance: 'balance',
      load: 'load',
      booths: 'booths',
    },
  },

  notFound: '404',

  desktop: {
    ticket: 'ticket',
    sponsor: 'sponsor',
    news: 'news',
    newsArticle: 'news/:newsId',
    results: 'results/:id',
    resultSegment: 'results',
    balance: 'balance',
    profile: 'user/profile',
    kyc: 'kyc',

    money: {
      card: 'load/card',
      deposit: 'load/deposit',
      activities: 'activities',
      annualSummary: 'annual-summary',
      load: 'load',
      unload: 'unload',
      tpv: 'tpv',
      bizum: {
        path: 'bizum',
        config: 'config',
        tpv: 'load',
      },
      mbWay: {
        path: 'mb-way',
        config: 'config',
        tpv: 'load',
      },
      requestGreatPrize: 'request-great-prize',
      requestGreatPrizeBigPrizeParam: 'big-prize',
      requestGreatPrizeMediumPrizeByIntegratorParam: 'medium-prize',
      requestMediumPrize: 'request-medium-prize',
    },

    shipments: {
      shipComplete: 'shipment-completed',
      collectionComplete: 'collection-completed',
    },

    user: {
      data: 'data',
      phone: 'phone',
      sms: 'sms',
      smsCodeParam: 'code',
      kyc: 'identity',
      login: 'login',
    },
  },
  mobile: {
    push: 'push-notifications',
    blockedNotifications: 'blocked-notifications',
    installation: 'installations',
    load: 'money/load',
    unload: 'money/unload',
    requestGreatPrize: 'money/request-great-prize',
    requestGreatPrizeTicket: 'ticket',
    requestGreatPrizeBigPrizeParam: 'big-prize',
    requestGreatPrizeMediumPrizeByIntegratorParam: 'medium-prize',
    requestMediumPrize: 'money/request-medium-prize',
    requestMediumPrizeTicket: 'get',
    requestMediumPrizeTicketInPerson: 'in-person',
    requestMediumPrizeTicketInPersonAuthorize: 'authorize',
    requestMediumPrizeClaimPrize: 'claim',
    requestMediumPrizeShipment: 'shipment',
    requestMediumPrizeCollectionReceipt: 'receipt',
    annualSummary: 'money/resumen-anual',
    clubHelp: 'play/clubs/help',
    googleContactsClub: 'play/clubs/:gameId/:raffleId/:tabId/share/google-contacts',
    clubsList: 'play/clubs/:gameId/:raffleId',
    newsArticle: 'user/news',
    init: 'user/init',
    kyc: 'user/identity',
    sponsorFriends: 'user/sponsor/friends',
    sponsorHelp: 'user/sponsor/help',
    sponsorSms: 'user/sponsor/sms',
    googleContacts: 'user/google-contacts',
    login: 'user/login',
    rememberPwd: 'user/login/remember',
    rememberSegment: 'remember',
    userPhone: 'user/phone',
    userPhoneSegment: 'phone',
    userData: 'user/data',
    userImage: 'user/profile/image',
    userSms: 'user/sms',
    smsSegment: 'sms',
    user: 'user',
    proof: 'tickets/ticket/:id/proff',
    shareTicket: 'tickets/ticket/:id/share',
    sharePercents: 'tickets/ticket/:id/share/precents',
    shareOptions: 'tickets/ticket/:id/share/options',
    ticketsByRaffle: 'tickets/raffle',

    main: {
      money: 'money',
      tickets: 'tickets',
      ticket: 'ticket',
      results: 'results',
      result: 'result',
      games: 'games',
      groups: 'groups',
      apk: 'apk',
      menu: 'menu',
    },

    boothSelector: {
      options: 'options',
    },
    favouriteBooth: {
      options: 'opciones',
    },

    scanner: {
      help: 'help',
      game: ':id',
      raffle: ':id/:raffleId',
      manual: 'manual',
    },

    results: {
      result: ':id',
      score: 'results',
    },

    money: {
      tpv: 'tpv',
      bizum: 'bizum',
      mbWay: 'mb-way',
    },

    shipments: {
      address: 'address',
    },

    tuloteroSlides: {
      profile: 'profile',
      sponsor: 'sponsor',
      news: 'news',
    },

    sponsorContacts: {
      ranking: 'ranking',
      achieved: 'achieved',
      contacts: 'contacts',
      friends: 'tulotero-friends',
      help: 'help',
    },
  },
};
